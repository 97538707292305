import { useRouter } from 'next/navigation'
import Banner from '@/components/RollingBanner/Banner'
import { BANNER_DATA } from '@/components/RollingBanner/consts'
import { URL_PATH } from '@/consts'
import Wrapper from './Wrapper'

const BANNER_INDEX = 3

const PlusNudge = () => {
  const { push } = useRouter()

  return (
    <Wrapper>
      <Banner
        imgSrc={BANNER_DATA[BANNER_INDEX].imgSrc}
        gradient={BANNER_DATA[BANNER_INDEX].gradient}
        imgAlt={BANNER_DATA[BANNER_INDEX].imgAlt}
        gaSelector="main_plus"
        title={BANNER_DATA[BANNER_INDEX].title}
        onClick={() => push(URL_PATH.PlusPreregistration)}
      />
    </Wrapper>
  )
}

export default PlusNudge
