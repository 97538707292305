import Image from 'next/image'
import Link from 'next/link'
import { S3_ICON_URL } from '@/config'
import { HOTLINE_TELEPHONE, URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import useMyInfo from '@/hooks/useMyInfo'

const Menu = () => {
  const { isLoggedIn } = useAuth()
  const { myInfo: { report_updated_label } = {} } = useMyInfo()

  const ICONS = [
    {
      label: '치료비 계산기',
      icon: 'ic-menu-calculator',
      link: URL_PATH.Calculator,
      gtm: 'main_features_caculator',
    },
    {
      label: '병원 예약',
      icon: 'ic-menu-hospital',
      link: isLoggedIn
        ? URL_PATH.MyHospitalsAppointments
        : URL_PATH.HospitalsAppointment,
      gtm: 'main_features_appointments',
    },
    {
      label: '질문과 답변',
      icon: 'ic-menu-question-and-answer',
      link: URL_PATH.Boards,
      gtm: 'main_features_boards',
    },
    {
      label: 'AI 진료 노트',
      icon: 'ic-menu-ai-notes',
      link: URL_PATH.AINotes,
      gtm: 'main_features_notes',
    },
    {
      label: '항암 정보',
      icon: 'ic-menu-chemotheraphy',
      link: isLoggedIn ? URL_PATH.MyRegimens : URL_PATH.Regimens,
      gtm: 'main_features_regimens',
    },
    {
      label: '의료팀 핫라인',
      icon: 'ic-menu-hotline-new',
      link: `tel:${HOTLINE_TELEPHONE}`,
      gtm: 'main_features_hotline',
    },
    {
      label: 'AI 영양사',
      icon: 'ic-menu-ai-assistant',
      link: isLoggedIn ? URL_PATH.MyAssistant : URL_PATH.Assistant,
      gtm: 'main_features_assistant',
    },
    {
      label: '플러스 리포트',
      icon: 'ic-menu-plus-report',
      link:
        isLoggedIn && report_updated_label
          ? URL_PATH.MyPlusReport
          : URL_PATH.PlusSampleReport,
      gtm: 'main_features_report',
    },
    {
      label: '플러스 상담',
      icon: 'ic-menu-plus-consultation',
      link: URL_PATH.PlusPreregistration,
      gtm: 'main_features_plus',
    },
  ]

  return (
    <div className="grid grid-cols-3 gap-x-2 gap-y-5">
      {ICONS.map(({ label, icon, link, gtm }) => (
        <Link
          key={icon}
          href={link}
          className="flex flex-col justify-center items-center h-[61px]"
          data-ga={gtm}
        >
          <Image
            src={`${S3_ICON_URL}/${icon}.svg`}
            width={36}
            height={36}
            alt=""
          />
          <p className="mt-1 prose-p3 font-medium">{label}</p>
        </Link>
      ))}
    </div>
  )
}

export default Menu
